import { ReactElement, ReactNode, ReactText } from 'react'

import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps
} from '@mui/material'

type CustomProps = {
  required?: boolean
  helperText?: ReactText | ReactElement | ReactNode | null
  helperProps?: FormHelperTextProps
  label?: string | ReactNode
  labelProps?: FormLabelProps
  error?: boolean
  errorMessage?: string | null
  errorProps?: FormHelperTextProps
  type?: 'switch' | 'field'
}

export type FormControlProps = CustomProps & MuiFormControlProps

const FormControl: RFC<FormControlProps> = ({
  helperText,
  helperProps,
  label,
  required,
  children,
  errorMessage,
  error,
  errorProps,
  labelProps,
  type = 'field',
  sx,
  ...rest
}) => {
  const isError = !!errorMessage || error
  const isSwitch = type === 'switch'
  const showHelperText = helperText && !isError

  return (
    <MuiFormControl
      fullWidth
      sx={{
        ...(isSwitch && {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',

          '& > label': {
            fontSize: '14px'
          }
        }),
        ...(!isSwitch && {
          '& .MuiFormLabel-root': {
            mb: '3px'
          }
        }),
        ...sx
      }}
      {...rest}
      required={required}
    >
      {!!label && (
        <FormLabel
          focused={false}
          filled
          required={required}
          error={isError}
          {...labelProps}
        >
          {label}
        </FormLabel>
      )}

      {children}

      {showHelperText && (
        <FormHelperText
          variant="filled"
          {...helperProps}
          sx={{ m: 0, ...helperProps?.sx }}
        >
          {helperText}
        </FormHelperText>
      )}

      {!!errorMessage && (
        <FormHelperText
          error={isError}
          {...errorProps}
          sx={{ m: 0, ...errorProps?.sx }}
        >
          {errorMessage}
        </FormHelperText>
      )}
    </MuiFormControl>
  )
}

export default FormControl
